import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { persistStore, persistReducer } from "redux-persist";
import { AuthSliceReducer } from "./slices/auth.slice";
import { CurrentUserSliceReducer } from "./slices/current-user.slice";
import { HiddenUserMessagesReducer } from "./slices/hidden-user-messages.slice";
import { AppReducer } from "./slices/app.slice";
import storage from "redux-persist/lib/storage";

const appPersistConfig = {
	key: "app-reducer",
	storage,
};

const hiddenMessagePersistConfig = {
	key: "hidden-messaage-reducer",
	storage,
};

const persistedAppReducer = persistReducer(appPersistConfig, AppReducer);
const persistedHiddenMessageReducer = persistReducer(
	hiddenMessagePersistConfig,
	HiddenUserMessagesReducer,
);

export const ReduxStore = configureStore({
	reducer: {
		auth: AuthSliceReducer,
		currentUser: CurrentUserSliceReducer,
		hiddenUserMessages: persistedHiddenMessageReducer,
		app: persistedAppReducer,
	},
});

export const Persistor = persistStore(ReduxStore);

export type ReduxState = ReturnType<typeof ReduxStore.getState>;
export type TypedDispatch = typeof ReduxStore.dispatch;

export const useTypedDispatch: () => TypedDispatch = useDispatch;
export const useTypedSelector: TypedUseSelectorHook<ReduxState> = useSelector;
