import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { CommunicationSettingsScreen_Query } from "@generated/CommunicationSettingsScreen_Query.graphql";
/*import { Checkbox } from "primereact/checkbox";
import { CommunicationSettingsScreen_SupplyEfnNumberMutation } from "@generated/CommunicationSettingsScreen_SupplyEfnNumberMutation.graphql";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";*/
import { DefaultContainer } from "../../infecto-lms-webapp/containers/Default.container";
import { MainContainer } from "../containers/MainContainer";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../infecto-lms-webapp-impl/config";

const QUERY = graphql`
	query CommunicationSettingsScreen_Query {
		Viewer {
			Auth {
				currentUser {
					user {
						extension {
							... on InfectopharmUserExtensionImpl {
								salutation
								title
								lastName
								efnNumber
							}
						}
						...DefaultContainer_UserDataFragment
					}
				}
			}
		}
	}
`;

/*const COMMUNICATION_SETTINGS_FRAGMENT = graphql`
	fragment CommunicationSettingsScreen_CommunicationSettingsFragment on CommunicationSettings {
		id
		sendExpirationNoticeEmails
		sendExpirationNoticeNotifications
		sendInformRetryEmails
		sendInformRetryNotifications
	}
`;

const SET_COMMUNICATION_SETTINGS_MUTATION = graphql`
	mutation CommunicationSettingsScreen_SetCommunicationSettingsMutation(
		$input: SetCommunicationSettingsInput!
	) {
		Viewer {
			Email {
				setCommunicationSettings(input: $input) {
					communicationSettings {
						...CommunicationSettingsScreen_CommunicationSettingsFragment
					}
				}
			}
		}
	}
`;*/

/*const SUPPLY_EFN_NUMBER_MUTATION = graphql`
	mutation CommunicationSettingsScreen_SupplyEfnNumberMutation($input: SupplyEfnNumberInput!) {
		InfectopharmAuth {
			supplyEfnNumber(input: $input) {
				clientMutationId
				user {
					salutation
					title
					lastName
					efnNumber
				}
			}
		}
	}
`;*/

/*interface SupplyEfnNumberFormikState {
	efnNumber: string;
}*/

/*interface FormikState {
	sendExpirationNoticeEmails: boolean;
	sendExpirationNoticeNotifications: boolean;
	sendInformRetryEmails: boolean;
	sendInformRetryNotifications: boolean;
}*/

export function CommunicationSettingsScreen() {
	const data = useLazyLoadQuery<CommunicationSettingsScreen_Query>(QUERY, {});

	/*	const efnNumber = data.Viewer.Auth.currentUser?.efnNumber || null;*/

	/*	const communicationSettings =
		useFragment<CommunicationSettingsScreen_CommunicationSettingsFragment$key>(
			COMMUNICATION_SETTINGS_FRAGMENT,
			data.Viewer.Email.CommunicationSettings,
		);*/

	/*const [setCommunicationSettings, isSetting] =
		useMutation<CommunicationSettingsScreen_SetCommunicationSettingsMutation>(
			SET_COMMUNICATION_SETTINGS_MUTATION,
		);*/
	/*	const [supplyEfnNumber, isSupplyingEfnNumber] =
		useMutation<CommunicationSettingsScreen_SupplyEfnNumberMutation>(SUPPLY_EFN_NUMBER_MUTATION);*/

	/*	const formik = useFormik<FormikState>({
		initialValues: {
			sendExpirationNoticeEmails: communicationSettings.sendExpirationNoticeEmails,
			sendExpirationNoticeNotifications: communicationSettings.sendExpirationNoticeNotifications,
			sendInformRetryEmails: communicationSettings.sendInformRetryEmails,
			sendInformRetryNotifications: communicationSettings.sendInformRetryNotifications,
		},
		onSubmit: (values) => {
			setCommunicationSettings({
				variables: {
					input: {
						...values,
					},
				},
				onCompleted: () => {
					toast.success("Einstellungen erfolgreich gesetzt!");
				},
			});
		},
	});*/

	return (
		<DefaultContainer
			collapsed={false}
			screenName="Einstellungen"
			userDataFragmentRef={data.Viewer.Auth.currentUser?.user!}
		>
			<MainContainer className="bg-white mt-5">
				<h1>Einstellungen</h1>

				{/*<h2>Profil</h2>*/}
				{/*	{efnNumber && (
					<p>{`Ihre EFN-Nummer ist ${data.Viewer.Auth.currentUser?.efnNumber || ""}.`}</p>
				)}*/}
				{/*{!efnNumber && (
					<Formik
						initialValues={{
							efnNumber: efnNumber || "",
						}}
						onSubmit={(values: SupplyEfnNumberFormikState) => {
							supplyEfnNumber({
								variables: {
									input: {
										...values,
									},
								},
								onCompleted: () => {
									toast.success("EFN-Nummer erfolgreich gesetzt!");
								},
							});
						}}
					>
						<Form>
							<div className="grid p-fluid">
								<div className="col-12">
									<label htmlFor="efnNumber" className="block mb-2">
										EFN-Nummer
									</label>
									<div className="p-inputgroup">
										<Field name="efnNumber">
											{(fieldProps: any) => (
												<InputText placeholder="EFN-Nummer eintragen..." {...fieldProps.field} />
											)}
										</Field>
										<Button disabled={isSupplyingEfnNumber} type="submit" label="Einreichen" />
									</div>
								</div>
							</div>
						</Form>
					</Formik>
				)}*/}

				<h2>Newsletter</h2>
				<p>
					Ihre Newsletter-Einstellungen können Sie bequem in Ihrem{" "}
					<a
						className="text-primary"
						target="_blank"
						rel="noopener noreferrer"
						href={encodeURI(
							`${process.env.REACT_APP_USER_SYSTEM_BASE}/login/${LMS_WEBAPP_CONFIG_IMPL.brandId}/?origin=${process.env.REACT_APP_REDIRECT_BASE_64_APP_BASE}`,
						)}
					>
						Ihrem Benutzerkonto
					</a>{" "}
					ändern.
				</p>

				{/*		<h2>E-Mails</h2>

				<div className="col-12 mb-5 flex align-items-center">
					<Checkbox
						className="mr-3"
						inputId="sendExpirationNoticeEmails"
						disabled={isSetting}
						checked={formik.values.sendExpirationNoticeEmails}
						onChange={(e) => {
							formik.setFieldValue("sendExpirationNoticeEmails", e.checked);
							formik.handleSubmit();
						}}
					/>
					<label htmlFor="sendExpirationNoticeEmails" className="p-checkbox-label">
						Bitte informieren Sie mich per E-Mail, wenn ein Modul abgelaufen ist und die Lösungen
						eingesehen werden können.
					</label>
				</div>

				<div className="col-12 mb-5 flex align-items-center">
					<Checkbox
						className="mr-3"
						inputId="sendInformRetryEmails"
						disabled={isSetting}
						checked={formik.values.sendInformRetryEmails}
						onChange={(e) => {
							formik.setFieldValue("sendInformRetryEmails", e.checked);
							formik.handleSubmit();
						}}
					/>
					<label htmlFor="sendInformRetryEmails" className="p-checkbox-label">
						Bitte informieren Sie mich per E-Mail, wenn ich ein in der Vergangenheit falsch
						beantwortetes Modul nach Sperrfrist erneut beantworten kann.
					</label>
				</div>*/}

				{/*<h2>Mobile Benachrichtigungen</h2>

				<div className="col-12 mb-5 flex align-items-center">
					<Checkbox
						className="mr-3"
						inputId="sendExpirationNoticeNotifications"
						disabled={isSetting}
						checked={formik.values.sendExpirationNoticeNotifications}
						onChange={(e) => {
							formik.setFieldValue("sendExpirationNoticeNotifications", e.checked);
							formik.handleSubmit();
						}}
					/>
					<label htmlFor="sendExpirationNoticeNotifications" className="p-checkbox-label">
						Bitte informieren Sie mich per App-Benachrichtigung, wenn ein Modul abgelaufen ist und
						die Lösungen eingesehen werden können.
					</label>
				</div>

				<div className="col-12 mb-5 flex align-items-center">
					<Checkbox
						className="mr-3"
						inputId="sendInformRetryNotifications"
						disabled={isSetting}
						checked={formik.values.sendInformRetryNotifications}
						onChange={(e) => {
							formik.setFieldValue("sendInformRetryNotifications", e.checked);
							formik.handleSubmit();
						}}
					/>
					<label htmlFor="sendInformRetryNotifications" className="p-checkbox-label">
						Bitte informieren Sie mich per App-Benachrichtigung, wenn ich ein in der Vergangenheit
						falsch beantwortetes Modul nach Sperrfrist erneut beantworten kann.
					</label>
				</div>*/}
			</MainContainer>
		</DefaultContainer>
	);
}
