/**
 * @generated SignedSource<<3be2ca96d5d8112a8567039a8bd23d9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NodeCard_LimitedAuthorizationsFragment$data = {
  readonly id: string;
  readonly shortDescription: string | null;
  readonly structureDefinition: {
    readonly extension?: {
      readonly configConsequences?: {
        readonly rewardExpired: boolean;
      };
      readonly reducedDataInTree?: {
        readonly pacPoints: number | null;
        readonly pharmaPoints: number | null;
      };
    };
    readonly icon?: {
      readonly url: string | null;
    } | null;
    readonly title: string;
    readonly " $fragmentSpreads": FragmentRefs<"NodeTagList_LearnOpportunityRootStructureDefinitionFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"NodeIcons_NodeFragment">;
  readonly " $fragmentType": "NodeCard_LimitedAuthorizationsFragment";
};
export type NodeCard_LimitedAuthorizationsFragment$key = {
  readonly " $data"?: NodeCard_LimitedAuthorizationsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NodeCard_LimitedAuthorizationsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NodeCard_LimitedAuthorizationsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "File",
              "kind": "LinkedField",
              "name": "icon",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "extension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "InfectopharmTreeConfigConsequences",
                      "kind": "LinkedField",
                      "name": "configConsequences",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "rewardExpired",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ReducedDataInTree",
                      "kind": "LinkedField",
                      "name": "reducedDataInTree",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "pacPoints",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "pharmaPoints",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "LearnOpportunityRootExtensionImpl",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "NodeTagList_LearnOpportunityRootStructureDefinitionFragment"
            }
          ],
          "type": "LearnOpportunityRootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NodeIcons_NodeFragment"
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};

(node as any).hash = "7e2ea56089b2e383a9abf9c7c75dc2d3";

export default node;
