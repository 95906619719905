import React from "react";

interface OwnProps {
	duration?: number;
	text?: string;
}

export function DurationDisplay({ duration, text = "min" }: OwnProps) {
	return duration ? (
		<>
			{Math.ceil(duration / 60)} {text}
		</>
	) : null;
}
