import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from "../redux-store";

interface FeedbackPopupStatus {
	isFeedbackPopupClosedByUser: boolean;
}

const INITIAL_STATE: FeedbackPopupStatus = {
	isFeedbackPopupClosedByUser: false,
};

const appSlice = createSlice({
	name: "app-slice",
	initialState: INITIAL_STATE,
	reducers: {
		setIsFeedbackPopupClosedByUser: (state, action: PayloadAction<boolean>) => {
			state.isFeedbackPopupClosedByUser = action.payload;
		},
	},
});

export const { setIsFeedbackPopupClosedByUser } = appSlice.actions;

export const AppReducer = appSlice.reducer;

export const selectIsFeedbackPopupClosedByUser = (state: ReduxState) =>
	state.app.isFeedbackPopupClosedByUser;
