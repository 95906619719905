import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from "../redux-store";

export type UserMessages =
	| "noProcess"
	| "efnNeeded"
	| "doccheck"
	| "initialValidation"
	| "changeInProgress"
	| "inactive"
	| "salutationAustria";

export interface HiddenUserMessages {
	hiddenUserMessagesArray: UserMessages[];
}

const INITIAL_STATE: HiddenUserMessages = {
	hiddenUserMessagesArray: [],
};

const hiddenUserMessagesSlice = createSlice({
	name: "hidden-user-messages",
	initialState: INITIAL_STATE,
	reducers: {
		addHiddenUserMessage: (state, action: PayloadAction<UserMessages>) => {
			if (!state.hiddenUserMessagesArray.includes(action.payload)) {
				state.hiddenUserMessagesArray.push(action.payload);
			}
		},
		removeAllHiddenUserMessage: (state) => {
			state.hiddenUserMessagesArray = [];
		},
	},
});

export const { addHiddenUserMessage, removeAllHiddenUserMessage } = hiddenUserMessagesSlice.actions;

export const HiddenUserMessagesReducer = hiddenUserMessagesSlice.reducer;

export const selectHiddenUserMessages = (state: ReduxState) =>
	state.hiddenUserMessages.hiddenUserMessagesArray;
