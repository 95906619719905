import React from "react";
import moment from "moment-timezone";

interface OwnProps {
	value: string;
	hideTimezone?: boolean;
}

export const formatDateTime = (value: string, dateAndTime = true) => {
	return moment(stripUTC(value))
		.tz(moment.tz.guess(), true)
		.format(dateAndTime ? "DD.MM.YYYY HH:mm" : "DD.MM.YYYY");
};

export const formatDateTimeAndReturnOnlyDate = (value: string) => {
	const date = moment(stripUTC(value));
	if (!date.isValid()) {
		return "";
	}
	return date.toISOString().split("T")[0].split("-").reverse().join(".");
};

export const formatDateBasedOnInput = (value: string, dateAndTime = true): string => {
	let formattedDate;
	const hasTime = value.includes("T") && value.includes("Z");
	if (hasTime) {
		formattedDate = moment(stripUTC(value)).tz(moment.tz.guess(), true);
	} else {
		formattedDate = moment(value, "DD.MM.YYYY");
	}
	if (!formattedDate.isValid()) {
		return "";
	}
	const format = hasTime && dateAndTime ? "DD.MM.YYYY HH:mm" : "DD.MM.YYYY";

	return formattedDate.format(format);
};

export function DateTimeDisplay({ value, hideTimezone }: OwnProps) {
	const formattedDateTime = moment(stripUTC(value))
		.tz(moment.tz.guess(), true)
		.format("DD.MM.YYYY HH:mm");

	if (hideTimezone) {
		return <span>{formattedDateTime}</span>;
	}

	return (
		<div>
			<div>{formattedDateTime}</div>
			{!hideTimezone && <small>{moment.tz.guess()}</small>}
		</div>
	);
}

export const stripUTC = (date: string | null | undefined) => {
	if (!date) {
		return "";
	}
	return date.replace("[UTC]", "");
};
